import React from 'react';
import { Link } from 'react-router-dom';

const HomePage = () => (
  <main style={{ padding: '2rem', textAlign: 'center' }}>
    <h1>Oформи ОСАГО за 5 кликов</h1>
    <p>Choose a category to get started:</p>
    <div style={{ display: 'flex', justifyContent: 'center', gap: '1rem' }}>
      <Link to="/insurance" style={{ padding: '1rem', backgroundColor: '#007BFF', color: 'white', borderRadius: '5px' }}>Страховые продукты</Link>
      <Link to="/banking" style={{ padding: '1rem', backgroundColor: '#28A745', color: 'white', borderRadius: '5px' }}>Банковские продукты</Link>
      <Link to="/other-services" style={{ padding: '1rem', backgroundColor: '#17A2B8', color: 'white', borderRadius: '5px' }}>Другие сервисы</Link>
    </div>
  </main>
);

export default HomePage;