import React from 'react';
import CategoryPage from '../../components/CategoryPage';

const Banking = () => {
  const widgetCode = `
    <iframe
      src="https://pampadu.com/widget/banking"
      style="width: 100%; height: 500px; border: none;"
    ></iframe>
  `;

  return <CategoryPage widgetCode={widgetCode} />;
};

export default Banking;