import React from 'react';
import { Link } from 'react-router-dom';

const Navbar = () => (
  <nav style={styles.navbar}>
    <div style={styles.logo}><Link to="/" style={styles.logo}>OSAGOnline.com</Link></div>
    <ul style={styles.navLinks}>
      <li><Link to="/" style={styles.link}>Главная</Link></li>
      <li><Link to="/insurance" style={styles.link}>Страховые продукты</Link></li>
      <li><Link to="/banking" style={styles.link}>Банковские продукты</Link></li>
      <li><Link to="/other-services" style={styles.link}>Другие сервисы</Link></li>
    </ul>
  </nav>
);

const styles = {
  navbar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '1rem 2rem',
    backgroundColor: '#007BFF',
    color: 'white',
    position: 'sticky',
    top: '0',
    zIndex: '1000',
  },
  logo: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
    textDecoration: 'none',
    color: "#ffffff"
  },
  navLinks: {
    listStyleType: 'none',
    display: 'flex',
    gap: '1rem',
    margin: 0,
    padding: 0,
  },
  link: {
    color: 'white',
    textDecoration: 'none',
    fontSize: '1rem',
    fontWeight: '500',
  },
};

export default Navbar;